import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store.js'
import axios from 'axios'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueTheMask from 'vue-the-mask'
import VueExcelXlsx from "vue-excel-xlsx"
import VModal from 'vue-js-modal'

Vue.config.productionTip = false

axios.defaults.baseURL = '/api'
axios.defaults.headers.common.Accept = 'application/json'
axios.interceptors.response.use(
    response => response,
    (error) => {
        return Promise.reject(error)
    }
)
Vue.prototype.$http = axios

const token = localStorage.getItem('token')
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer_' + token
}

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueTheMask)
Vue.use(VueExcelXlsx)
Vue.use(VModal)

new Vue({
    router,
    store,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')