<template>
  <div id="home">
    <b-container fluid>
      <MainMenu/>
      <router-view/>
    </b-container>
  </div>
</template>

<script>

    import MainMenu from '../components/MainMenu.vue'

    export default {
      name: 'Home',
      components: {MainMenu},
      data() {
        return {
          collapsed: true,
        }
      },
      methods: {
        logout: function () {
          console.log("logout")
          this.$store.dispatch('logout')
                  .then(() => {
                    this.$router.push('/main')
                  }).catch((error) => {
          })
        }
      },
      created() {
        var vm = this;
        this.$http.interceptors.response.use(undefined, function (err) {
          return new Promise(function (resolve, reject) {
            if ((!err.response || err.response.status === 401) && err.config && !err.config.isRetryRequest) {
              vm.logout()
            }
            throw err
          })
        })
      }
    }
</script>

<style>
  #home {
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 0;*/
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #466368;
    background: -webkit-linear-gradient(#8ec1b8, #293f50);
    background:    -moz-linear-gradient(#8ec1b8, #293f50);
    background:         linear-gradient(#8ec1b8, #293f50);
  }
</style>
