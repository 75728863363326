import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        user: localStorage.getItem('user'),
        roles: localStorage.getItem('roles'),
    },
    mutations: {
        auth_request (state) {
            state.status = 'loading'
        },
        auth_success (state, login) {
            state.status = 'success'
            state.token = login.token
            state.user = login.user
            state.roles = login.roles
        },
        auth_error (state) {
            state.status = 'error'
        },
        logout (state) {
            state.status = ''
            state.token = ''
        }
    },
    actions: {
        login ({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                var login = {}
                login.user = user.user
                login.token = user.token
                login.roles = user.roles
                commit('auth_success', login)
                axios.defaults.headers.common['Authorization'] = 'Bearer_' + user.token
            })
        },
        logout ({commit}) {
            return new Promise((resolve, reject) => {
                commit('logout')
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('roles')
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        getUserInfo: state => state.user,
        authStatus: state => state.status,
        getRoles: state => state.roles
    }
})