<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  export default {
      // methods: {
      //     logout: function () {
      //         console.log("logout")
      //         this.$store.dispatch('logout')
      //             .then(() => {
      //                 this.$router.push('/main')
      //             }).catch((error) => {
      //         })
      //     }
      // },
      // created() {
      //     var vm = this;
      //     this.$http.interceptors.response.use(undefined, function (err) {
      //         return new Promise(function (resolve, reject) {
      //             if ((!err.response || err.response.status === 401) && err.config && !err.config.isRetryRequest) {
      //                 vm.logout()
      //             }
      //             throw err
      //         })
      //     })
      // }
  }
</script>

<style>
  #app {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    /*background: #466368;*/
    /*background: -webkit-linear-gradient(#8ec1b8, #293f50);*/
    /*background:    -moz-linear-gradient(#8ec1b8, #293f50);*/
    /*background:         linear-gradient(#8ec1b8, #293f50);*/
  }

  .btn-grad-primary {
    background-image: linear-gradient(to right, #134E5E 0%, #71B280  51%, #134E5E  100%);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;}
  .btn-grad-primary {
    /*margin: 10px;*/
    /*padding: 15px 45px;*/
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: rgb(38, 57, 77) 0px 10px 20px -10px;
    border-radius: 5px;
    display: block;
  }

  .btn-grad-primary:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
  .btn-shadow {
    box-shadow: rgb(38, 57, 77) 0px 10px 20px -10px;
  }

</style>
