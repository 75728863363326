<template>
    <div class="px-3 py-2 list__container">
        <!--<b-list-group class="mb-2">-->
        <b-button-group vertical class="mb-1" size="md">
            <b-button block class="btn-grad font-weight-bold mb-1" to="/">Единый календарный план</b-button>
            <!--<b-list-group-item variant="dark" class="font-weight-bold" href="/">-->
            <!--</b-list-group-item>-->
            <b-button block class="btn-grad font-weight-bold mb-1" to="/news">Новости</b-button>
            <b-button block class="btn-grad font-weight-bold mb-1" to="/contacts">Контакты</b-button>
        <!--</b-list-group>-->
        </b-button-group>

        <b-button v-if="!isLoggedIn" block size="sm" pill class="btn-grad2 font-weight-bold text-dark" @click="openLoginForm()">
            Войти в ИС ЕКП
        </b-button>
        <b-button v-if="isLoggedIn" block size="sm" pill class="btn-grad2 font-weight-bold text-dark" target="_blank" to="/ekp">
            Открыть ИС ЕКП
        </b-button>
        <b-button block size="sm" pill target="_blank" class="btn-grad2 font-weight-bold text-dark" to="/CSP">
            Открыть ИС СФ
        </b-button>

        <div class="d-flex align-items-center px-3 py-2 list__description">
            <b-button block v-show="isLoggedIn" pill size="sm" variant="danger" to="/logout" class="mt-5" id="btn-logout">Выйти <b><u>{{getUserName()}}</u></b></b-button>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'sidebarList',
        data() {
            return {
                loginObj: {username: '', org: ''},
            }
        },
        created() {
            this.getUserInfo()
        },
        computed: {
            isLoggedIn: function () {
                return this.$store.getters.isLoggedIn
            },
        },
        methods: {
            openCSP() {
                window.location.href = '/CSP';
            },
            openLoginForm() {
                this.$emit("openLoginForm")
            },
            logout: function () {
                console.log("logout")
                this.$store.dispatch('logout')
                    .then(() => {
                       // this.$router.push('/main')
                    }).catch((error) => {
                })
            },
            getUserInfo() {
                // var login = localStorage.getItem("user")
                var vm = this
                axios.get('/site/statusUser')
                    .then((response) => {
                        vm.loginObj.username = response.data
                    })
                    .catch((error) => {
                       vm.logout()
                    })
            },
            getUserName() {
                if (this.loginObj.username)
                    return this.loginObj.username
                else {
                    // this.$store.dispatch('logout')
                    return ''
                }
            }
        }
    }
</script>

<style>
    .list__container {
        position: relative;
        /*margin: auto auto auto auto;*/
    }
    .list__description {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
    }
    .list__container .btn-info, .btn-info:hover, .btn-info:active, .btn-info:visited {
        background-color: #abc888 !important;
        color: black;
    }
    .list__container .btn-info2, .btn-info2:hover, .btn-info2:active, .btn-info2:visited {
        background-color: #69a3c8 !important;
        color: white;
    }
    .list__container .btn-danger, .btn-danger:hover, .btn-danger:active, .btn-danger:visited {
        background-color: #c85931 !important;
    }

    .btn-grad {background-image: linear-gradient(to right, #314755 0%, #26a0da  51%, #314755  100%)}
    .btn-grad {
        /*margin: 10px;*/
        /*padding: 15px 45px;*/
        text-align: center;
        text-transform: uppercase;
        transition: 0.5s;
        background-size: 200% auto;
        color: white;
        box-shadow: 0 0 20px #eee;
        border-radius: 10px;
        display: block;
    }

    .btn-grad:hover {
        background-position: right center; /* change the direction of the change here */
        color: #fff;
        text-decoration: none;
    }

    .btn-grad2 {background-image: linear-gradient(to right, #1D976C 0%, #93F9B9  51%, #1D976C  100%)}
    .btn-grad2 {
        /*margin: 10px;*/
        /*padding: 15px 45px;*/
        text-align: center;
        text-transform: uppercase;
        transition: 0.5s;
        background-size: 200% auto;
        color: black;
        box-shadow: rgb(38, 57, 77) 0px 10px 20px -10px;
        border-radius: 10px;
        display: block;
    }

    .btn-grad2:hover {
        background-position: right center; /* change the direction of the change here */
        color: black;
        text-decoration: none;
    }
    #btn-logout {
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    }

</style>