import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from './views/LoginPage.vue'
import Privacy from './views/Privacy.vue'
import HomePage from './views/Home.vue'
import StartPage from './views/StartPage'
import Store from './store'

Vue.use(VueRouter)

const routes = [
    {
        name: 'sportekp',
        path: '/',
        redirect: '/main',
        component: StartPage,
        meta: {
            title: 'Добро пожаловать'
        },
        children: [
            {
                path: '/main',
                name: 'main',
                component: () => import('./components/MainPage.vue'),
                meta: {
                    title: 'Главная'
                }
            },
            {
                path: '/news',
                name: 'news',
                component: () => import('./views/News.vue'),
                meta: {
                    title: 'Новости'
                }
            },
            {
                path: '/news/:id',
                name: 'posts',
                component: () => import('./views/NewsView.vue'),
                meta: {
                    title: 'Новости'
                }
            },
            {
                path: '/contacts',
                name: 'contacts',
                component: () => import('./views/Contacts.vue'),
                meta: {
                    title: 'Контакты'
                }
            },
            ]
    },
    {
        name: 'ekp',
        path: '/ekp',
        component: HomePage,
        meta: {
            requiresAuth: true,
            title: 'ИС ЕКП'
        },
        children: [
            {
                path: '/sportkind',
                name: 'sportkind',
                component: () => import('./views/SportKind.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Разделы ЕКП'
                }
            },
            {
                path: '/people',
                name: 'people',
                component: () => import('./views/PeoplePage.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Ответственные'
                }
            },
            {
                component: () => import('./views/LocationPage.vue'),
                path: '/location',
                name: 'location',
                meta: {
                    requiresAuth: true,
                    title: 'Объекты спорта'
                }
            },
            {
                path: '/federslist',
                name: 'federslist',
                component: () => import('./views/FederationsListPage.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Федерации'
                }
            },
            {
                path: '/organization',
                name: 'organization',
                component: () => import('./views/Organization.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Организации'
                }
            },
            {
                path: '/userslist',
                name: 'userslist',
                component: () => import('./views/UsersListPage.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Пользователи'
                }
            },
            {
                component: () => import('./views/EventKind.vue'),
                path: '/eventkind',
                name: 'eventkind',
                meta: {
                    requiresAuth: true,
                    title: 'Статусы'
                }
            },
            {
                component: () => import('./views/EventState.vue'),
                name: 'eventstate',
                path: '/eventstate',
                meta: {
                    requiresAuth: true,
                    title: 'Состояния'
                }
            },
            {
                component: () => import('./views/EkpTabPage.vue'),
                path: '/data',
                name: 'data',
                meta: {
                    requiresAuth: true,
                    title: 'ЕКП'
                }
            },
            {
                component: () => import('./views/SettingSmeta'),
                name: 'settingsmeta',
                path: '/settingsmeta',
                meta: {
                    requiresAuth: true,
                    title: 'Настройки сметы'
                }
            },
            {
                component: () => import('./views/SettingEkp'),
                name: 'settingekp',
                path: '/settingekp',
                meta: {
                    requiresAuth: true,
                    title: 'Настройки цвета'
                }
            },
            {
                component: () => import('./views/NormPage'),
                name: 'norm',
                path: '/norm',
                meta: {
                    requiresAuth: true,
                    title: 'Нормы'
                }
            },
            {
                component: () => import('./views/reports/ReportEkp'),
                name: 'reportekp',
                path: '/reportekp',
                meta: {
                    requiresAuth: true,
                    title: 'Отчет ЕКП'
                }
            }, {
                component: () => import('./views/reports/ReportKind'),
                name: 'reportkind',
                path: '/reportkind',
                meta: {
                    requiresAuth: true,
                    title: 'Сводный Отчет'
                }
            }, {
                component: () => import('./views/reports/ReportCost'),
                name: 'reportcost',
                path: '/reportcost',
                meta: {
                    requiresAuth: true,
                    title: 'Итоговый отчет'
                }
            }, {
                component: () => import('./components/UserBoard'),
                path: '/dashboard',
                name: 'userboard',
                meta: {
                    requiresAuth: true
                }
            },
            {
                component: () => import('./views/limit/LimitEkp.vue'),
                path: '/limit',
                name: 'limit',
                meta: {
                    requiresAuth: true,
                    title: 'Лимиты'}
            },
            {
                component: () => import('./views/AnalyticCodePage.vue'),
                path: '/analyticCode',
                name: 'analyticCode',
                meta: {
                    requiresAuth: true,
                    title: 'КОСГУ(Статьи)'
                }
            },
            {
                component: () => import('./views/WorkKind.vue'),
                path: '/workkind',
                name: 'workkind',
                meta: {
                    requiresAuth: true,
                    title: 'Характеристики ЕКП'
                }
            },
            {
                component: () => import('./views/RolesPage.vue'),
                path: '/roles',
                name: 'roles',
                meta: {
                    requiresAuth: true,
                    title: 'Роли'
                }
            },
            {
                component: () => import('./views/PassChange.vue'),
                path: '/changePwd',
                name: 'changePwd',
                meta: {
                    requiresAuth: true,
                    title: 'Смена пароля'
                }
            },
            {
                component: () => import('./views/RequestEvent.vue'),
                path: '/requestEvent',
                name: 'requestEvent',
                meta: {
                    title: 'Заявки на мероприятия'
                }
            },
            {
                component: () => import('./views/RequestSport.vue'),
                path: '/requestSport',
                name: 'requestSport',
                meta: {
                    title: 'Заявки на участие'
                }
            },
            {
                component: () => import('./views/RankPage.vue'),
                path: '/rank',
                name: 'rank',
                meta: {
                    title: 'Справочник Званий'
                }
            },
            {
                name: 'Справочник Школ',
                component: () => import('./views/SchoolPage.vue'),
                path: '/school',
                meta: {
                    title: 'Справочник Школ'
                },
            },
            {
                name: 'Отчет по заявкам',
                component: () => import('./views/reports/ReportRequest.vue'),
                path: '/reportrequest',
                meta: {
                    title: 'Отчет по заявкам'
                },
            },
            {
                name: 'Спортсмены',
                component: () => import('./views/PeopleSport.vue'),
                path: '/peopleSport',
                meta: {
                    title: 'Спортсмены'
                },
            },
            {
                name: 'Документы',
                component: () => import('./views/Document.vue'),
                path: '/document',
                meta: {
                    title: 'Документы'
                },
            },
            {
                name: 'Журналирование',
                component: () => import('./views/loggingForm/LoggingForm.vue'),
                path: '/logging',
                meta: {
                    title: 'Журналирование'
                },
            },
            {
                component: () => import('./views/moveSumma/MoveSummaEkp.vue'),
                name: 'moveSumma',
                path: '/moveSumma',
                meta: {
                    requiresAuth: true,
                    title: 'Перенос сумм мероприятия'
                }
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: {
            title: 'Вход'
        }
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy,
        meta: {
            title: 'Политика обработки персональных данных'
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const DEFAULT_TITLE = 'ИС';
router.afterEach((to, from) => {
    document.title = to.meta.title || DEFAULT_TITLE;
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (Store.getters.isLoggedIn) {
            let roles = JSON.parse(localStorage.getItem('roles'));
            switch(to.name) {
                case 'settingsmeta':
                    if (roles.indexOf("ADMIN") >= 0 || roles.indexOf("UPDATE_SETT_SMETA") >= 0) {
                        next();
                        break
                    } else {
                        next('/dashboard');
                    }
                    break;
                case 'userslist':
                case 'settingekp':
                case 'roles':
                case 'organization':
                    if (roles.indexOf("ADMIN") >= 0) {
                        next();
                        break
                    } else {
                        next('/dashboard');
                    }
                    break;
                case 'limit' :
                    if (roles.indexOf("ADMIN") >= 0 || roles.indexOf("UPDATE_LIMIT") >= 0) {
                        next()
                    } else {
                        next('/dashboard');
                    }
                    break;
                case 'reportcost':
                case 'reportkind':
                case 'reportekp':
                    if (roles.indexOf("ADMIN") >= 0 || roles.indexOf("UPDATE_REPORT") >= 0) {
                        next()
                    } else {
                        next('/dashboard');
                    }
                    break;
                case 'norm':
                    if (roles.indexOf("ADMIN") >= 0 || roles.indexOf("UPDATE_NORM") >= 0) {
                        next()
                    } else {
                        next('/dashboard');
                    }
                    break;
                default:
                    next()
            }
            return
        }
        Store.dispatch('logout')
        next('/')
    } else {
        if (to.path === '/logout') {
            Store.dispatch('logout')
            next('/')
        } else
            next()
    }
})

export default router
